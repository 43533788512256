<template>
  <div class="stocks__items">
    <locale-router-link
      v-for="item in items"
      :key="item.id"
      :to="getComponent(item)"
      class="stocks__item"
      ><img
        v-lazy="
          $helpers.getAbsolutePath(
            item.attributes.preview_desktop.data.attributes.url
          )
        "
        alt="stock image"
        width="380"
        height="320"
    /></locale-router-link>
  </div>
</template>

<script>
export default {
  name: "StocksList",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  // mounted() {
  // },
  methods: {
    getComponent(item) {
      if (item.attributes.promo_type === "only_discount") {
        return "stocks/products/" + item.id;
      } else {
        return "stocks/" + item.id;
      }
    },
  },
};
</script>
